<template>
  <div class="inventories">
    <delete-modal v-if="selectedInventoryToDelete" ref="delete-modal" @confirmDelete="handleConfirmDelete"
      @cancelDelete="handleCancelDelete">
      <template v-slot:delete-message>
        <p>
          <i18n path="inventory.confirmDelete">
            <template v-slot:inventorytitle>
              <strong>{{ selectedInventoryToDelete.name }}</strong>
            </template>
          </i18n>
        </p>

      </template>
    </delete-modal>
    <LoadingAnimation v-if="loading" />
    <b-table v-if="!loading" ref="inventories" :items="filteredList" :fields="fields" @row-clicked="openInventory"
      sticky-header>
      <template v-slot:cell(actions)="data" v-if="!isInventoryOnly">
        <DotMenu>
          <template v-slot:default>
            <b-dropdown-item-button @click="editInventory(data.item)">{{ $t('general.buttons.edit')
              }}</b-dropdown-item-button>
            <b-dropdown-item-button @click="deleteInventory(data.item)">{{ $t('general.buttons.delete')
              }}</b-dropdown-item-button>
          </template>
        </DotMenu>
      </template>
      <template v-slot:cell(percentage)="data">
        {{ getProgress(data.item.inventoryVenueWines) }} %
      </template>
      <template v-slot:cell(startDate)="data">
        {{ new Date(data.item.startDate).toLocaleDateString() }}
      </template>

      <template v-slot:cell(inventoryState)="data">

        <InventoryState :inventoryState="data.item.inventoryState" />

      </template>
      <template v-slot:cell(inventoryType)="data">

        {{ $t(`inventory.${data.item.inventoryType}`) }}

      </template>


    </b-table>
  </div>
</template>

<script>
import DataService from '@/services/StrapiService'
import DataMappingService from '@/services/DataMappingService'
import DotMenu from '@/components/DotMenu.vue'
import { mapGetters } from 'vuex'
import InventoryState from '@/components/inventory/InventoryState.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';

export default {
  data() {
    return {
      inventories: [],
      searchFilter: '',
      loading: false,
      selectedInventoryToDelete: {}
    }
  },
  created() {
    this.loadInventories()
  },
  mounted() {
    this.$root.$on('changedSearchFilter', (filter) => {
      this.searchFilter = filter
    })

  },
  props: {

  },
  components: {
    DotMenu,
    InventoryState,
    LoadingAnimation,
    DeleteModal
  },


  methods: {
    getProgress(inventoryVenueWines) {
      if (inventoryVenueWines.length === 0) return 0
      
      const totalActualAmount = inventoryVenueWines.reduce((total, wine) => total + Number(wine.actualAmount), 0);
      const totalTargetAmount = inventoryVenueWines.reduce((total, wine) => total + Number(wine.targetAmount), 0);

      const percentage = Math.round((Number(totalActualAmount) / Number(totalTargetAmount) * 100))


      return percentage
    },
    formatInventoryState(inventoryState) {
      return this.$t(`inventory.state.${inventoryState}`)
    },
    editInventory(inventory) {
      this.$bvModal.show('inventory-modal', inventory);
      this.$root.$emit('editInventory', inventory)
    },
    openInventory(item) {

      this.$store.commit('setTopBarHeadLine', { texts: [{ text: this.$t('inventory.inventories'), link: { name: 'Inventories' } }, { text: item.name }], inventorystate: item.inventoryState })
      this.$router.push({ name: 'Inventory', params: { id: item.id } })
    },
    deleteInventory(inventory) {
      this.$bvModal.show('delete-modal')
      this.selectedInventoryToDelete = inventory
    },
    handleConfirmDelete() {
      this.$bvModal.hide('delete-modal')
      DataService.deleteInventory(this.selectedInventoryToDelete.id)
        .then(() => {
          this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.successDelete"), variant: "success" });
          this.loadInventories()
        })
        .catch(error => {
          this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.errorDelete"), variant: "danger" });
          console.error(error)
        })
    },
    handleCancelDelete() {
      this.$bvModal.hide('delete-modal')
      this.selectedInventoryToDelete = {}
    },

    loadInventories() {
      this.loading = true
      DataService.getInventories()
        .then(response => {
          this.inventories = DataMappingService.mapGetInventoriesResponse(response)

          this.loading = false
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
  },
  computed: {
    ...mapGetters(['isInventoryOnly']),
    filteredList() {
      const filtered = this.inventories.filter((inventory) => {
        const matches = inventory.name.toLowerCase().includes(this.searchFilter.toLowerCase());
        return matches;
      });
      return filtered
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('inventory.nameOfInventory'),
          sortable: true
        },
        {
          key: 'inventoryType',
          label: this.$t('inventory.type'),
          sortable: true
        },
        {
          key: 'startDate',
          label: this.$t('general.date'),
          sortable: true
        },
        {
          key: 'percentage',
          label: this.$t('inventory.progress'),
          sortable: true
        },
        {
          key: 'inventoryState',
          label: this.$t('inventory.state.title'),
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        }
      ]
    },
  }
}
</script>

<style scoped></style>